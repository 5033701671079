$(window).on('mousemove',function(e){

  var width=$(window).width(),
      height=$(window).height(),
      offsetX=0.5-e.pageX/width,
      offsetY=0.5-e.pageY/height,
      $parallaxItem=$('.parallax-img');

  $parallaxItem.each(function(i,el){
    var offset=parseInt($(el).data('offset')),       translate="translate3d("+Math.round(offsetX*offset)+"px,"+Math.round(offsetY*offset)+"px,0px)";
    $(el).css({
      '-webkit-transform':translate,
      'transform':translate,
      '-moz-transform':translate
    });
  });
});